<template>
  <div class="course_list_page">
    <div class="left">
      <teacherInfo />
    </div>
    <div class="right">
      <div class="course_Info">
        <div class="img_block">
          <img :src="constant.URL + info.image" alt="" />
        </div>
        <div class="course_info_right">
          <div v-if="!this.$route.query.welfare">
            <div class="title1" v-show="this.$route.query.courseId === '2'">
              体验课程
            </div>
            <div class="title1" v-show="this.$route.query.courseId === '1'">
              付费课程
            </div>
          </div>

          <div class="info_block" style="margin: 0.1rem 0">
            <!-- <span class="tab" :class="{block_active: tabClass == 1}" v-if="this.$route.query.welfare"  @click="tabCheck(1)" >小班</span>
            <span class="tab" :class="{block_active: tabClass === 37}"  v-if="this.$route.query.welfare" @click="tabCheck(37) ">中班</span>
            <span class="tab" :class="{block_active: tabClass === 38}"  v-if="this.$route.query.welfare" @click="tabCheck(38) ">大班</span>  -->
            <!-- <span class="tab" style=" padding: 0 0.2rem " @click="$store.commit('setOpenBuy',true);$store.commit('setBuyStep',1);">立即购买课程</span> -->
            <!-- 付费按钮 -->
            <div
              v-if="
                !this.$route.query.welfare && this.$route.query.courseId == '1' && !$store.state.userInfo.teacherId
              "
              @click="handPay"
            >
              <img
                src="../../assets/img/button/BrowserPreview_tmp-5.png"
                alt=""
                style="width: 250px; margin: 16px 0; cursor: pointer"
              />
            </div>
          </div>
          <!-- <div class="info_block">
            <span>课节：10节</span>
            <span
              >难度：{{
                info.difficulty == 0
                  ? "初级"
                  : info.difficulty == 1
                  ? "中级"
                  : "高级"
              }}</span
            >
          </div> -->
          <div class="desc" v-html="info.content"></div>
        </div>
      </div>
      <div class="course_block">
        <span style="display: inline-block" class="tab-one-box">选择课程</span>
        <div
          style="display: inline-block"
          v-if="this.$route.query.courseId == '1'"
        >
          <span
            class="tab-box"
            :class="{ info_block_active: tabClass == 1 }"
            @click="tabCheck(1)"
            >小班</span
          >
          <span
            class="tab-box"
            :class="{ info_block_active: tabClass === 37 }"
            @click="tabCheck(37)"
            >中班</span
          >
          <span
            class="tab-box"
            :class="{ info_block_active: tabClass === 38 }"
            @click="tabCheck(38)"
            >大班</span
          >
        </div>

        <div class="course_flex_block" v-if="$route.query.courseId == 39">
          <div v-for="item in list" :key="item.id">
            <div class="pgs_cont">
              <p class="pgs_cont_teacher" :title="item.title">{{item.title}}</p>
              <p class="pgs_cont_dec" :title="item.subtitle">{{item.subtitle}}</p>
            </div>
            <div class="pgs_course">
              <div
                  class="pgs_course_item tab"
                  @click="toAttend(sItem)"
                  :class="{ video_acitve: sItem.id === tabVideo.id }"
                  v-for="sItem in item.videoList"
                  :key="sItem.id"
                >
                  <div class="bar"></div>
                  <div class="title">第{{ sItem.lessons }}节</div>
                  <div class="desc">{{ sItem.videoname }}</div>
                  <div class="look" v-show="sItem.over"></div>
                  <div class="lock" v-show="sItem.publish === 0"></div>
                </div>
            </div>
            
          </div>
        </div>
        <div class="course_flex_block" v-else>
          <div
            class="course_item tab"
            @click="toAttend(item)"
            :class="{ video_acitve: item.id === tabVideo.id }"
            v-for="item in list"
            :key="item.id"
          >
            <div class="bar"></div>
            <div class="title">第{{ item.lessons }}节</div>
            <div class="desc">{{ item.videoname }}</div>
            <div class="look" v-show="item.over"></div>
            <div class="lock" v-show="item.publish === 0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teacherInfo from "@/components/course/teacherInfo.vue";
export default {
  name: "courseList",
  components: {
    teacherInfo,
  },
  data() {
    return {
      tabIndex: 0,
      apiData: "",
      info: {
        title: "",
        image: "",
      },
      list: [],
      tabVideo: {},
      tabClass: 1,
    };
  },
  created() {},
  mounted() {
    if(this.$route.query.courseId){
      this.apiData = this.$route.query;
    }else{
      this.apiData = { courseId: "2", isPremium: "0" };
    }
    if(this.$route.query.courseId == 39){
      this.getPgsCourseList();
    }else{
      this.getCourseList();
    }
  },
  methods: {
    // 付费按钮
    handPay() {
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
        // this.$router.push({ path: "/home" })
      } else {
        this.$router.push({
          path: "/product",
          query: {
            type: "class",
          },
        });
        // this.$store.commit("setOpenBuy", true);
        // this.$store.commit("setBuyStep", 1);
      }
    },
    getCourseList() {
      let data = {
        ...this.apiData,
      };
      this.tabClass = data.courseId;
      this.api.course.findCoursesOfTeacher(data).then((res) => {
        this.list = res.data;
        this.info = res.data[0].course;
      });
    },
    getPgsCourseList() {
      let data = {
        ...this.apiData,
      };
      this.tabClass = data.courseId;
      this.api.course.findCoursesOfTeacher_V2(data).then((res) => {
        this.list = res.data;
        this.info = res.data[0].videoList[0].course;
      });
    },
    //观看视频
    toAttend(item) {
      // 公益园进入 点击课程查看是否登录>是否是公益园
      // 点击上课 体验课程无需登录
      // 付费课程需要登录
      if (
        !this.$store.state.userInfo.token &&
        this.apiData.courseId != "2"
      ) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (!this.$store.state.userInfo.token && this.$route.query.welfare) {
        this.$store.commit("setLoginState", "login");
        return;
      }
      if (
        this.$store.state.userInfo.token &&
        this.apiData.courseId != "2"
      ) {
        console.log('1111')
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              if (res.data !== 5) {
                if (this.$route.query.welfare) {
                  this.$notice({
                    message:
                      "您不是公益幼儿园，请前往校园中心进行相关教学工作园用户使用",
                  }).isShow();
                  return;
                } else {
                  this.getNormaoInfo(item);
                }
              } else {
                this.tabVideo = item;
                if (item.publish === 0) {
                  this.$notice({
                    type: "success",
                    message: "敬请期待",
                  }).isShow();
                  return;
                }
                this.$router.push({
                  path: "/classVideo",
                  query: {
                    videoname: item.videoname,
                    id: item.id,
                    normalUrl: item.normalUrl,
                    videoinfo: item.videoinfo,
                    attachment: item.attachment || "",
                    welfare: "welfare",
                  },
                });
              }
            }
          });
        return;
      }

      // if (this.$route.query.courseId === "1") {
      //   this.$store.commit("setLoginState", "login");
      // }
      this.tabVideo = item;
      if (item.publish === 0) {
        this.$notice({
          type: "success",
          message: "敬请期待",
        }).isShow();
        return;
      }
      this.$router.push({
        path: "/classVideo",
        query: {
          videoname: item.videoname,
          id: item.id,
          normalUrl: item.normalUrl,
          videoinfo: item.videoinfo,
          attachment: item.attachment || "",
          isFree: this.apiData.courseId === "2" ,
        },
      });
    },
    // 非公益园判断接口
    getNormaoInfo(item) {
      this.api.unit
        .getPlayInfoByToken({ videoId: item.id, definition: "" })
        .then((res) => {
          if (res.flag) {
            this.tabVideo = item;
            if (item.publish === 0) {
              this.$notice({
                type: "success",
                message: "敬请期待",
              }).isShow();
              return;
            }
            this.$router.push({
              path: "/classVideo",
              query: {
                videoname: item.videoname,
                id: item.id,
                normalUrl: item.normalUrl,
                videoinfo: item.videoinfo,
                attachment: item.attachment || "",
              },
            });
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
    tabCheck(item) {
      let data = {
        courseId: item,
        isPremium: 1,
      };
      this.tabClass = item;
      this.api.course.findCoursesOfTeacher(data).then((res) => {
        this.list = res.data;
        this.info = res.data[0].course;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.course_list_page {
  width: 100%;
  padding-top: 73px;
  @include flex(row, center, flex-start);

  .left {
    .block {
      box-sizing: border-box;
      margin-bottom: 13px !important;
      font-family: Alibaba PuHuiTi;
      font-size: 31px !important;

      .first_title {
        width: 266px;
        height: 84px;
        border-top-right-radius: 45px;
        background: #cc1a30;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 81px;
      }

      .item_title {
        width: 266px;
        height: 69px;
        background: #f8f5f5;
        font-weight: 400;
        color: #666666;
        line-height: 69px;
        text-align: center;
        margin-bottom: 7px;
      }

      .active {
        color: #cc1a30;
        position: relative;
      }

      .active::after {
        content: "";
        position: absolute;
        top: 20px;
        right: 26px;
        width: 10px;
        height: 29px;
        @include bg("../../assets/img/lesson/leftsideactive.png");
      }
    }
  }

  .right {
    width: calc(100vw - 266px);
    min-width: 1351px;
    margin: 0 186px 0 110px;

    .course_Info {
      width: 100%;
      height: 387px;
      box-sizing: border-box;
      padding: 47px 51px;
      background: #2a3c6e;
      margin-bottom: 55px;
      @include flex();
      .img_block {
        width: 470px;
        height: 290px;
        background: #f8f5f5;
        border-radius: 25px;
        box-sizing: border-box;
        padding: 14px 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .course_info_right {
        flex: 1;
        margin-left: 39px;
        .title1 {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
        .info_block {
          @include flex(row, flex-start, center);
          margin: 24px 0 44px 0;
          span {
            width: 144px;
            height: 30px;
            background: #fefefe;
            border-radius: 15px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #2a3c6e;
            margin-right: 11px;
            @include flex();
          }
          .block_active {
            background: #ccc;
          }
        }
        .desc {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .course_block {
      margin-bottom: 37px;
      .tab-box {
        display: inline-block;
        width: 224px;
        height: 63px;
        // border-top-right-radius: 30px;
        // background: #cc1a30;
        background-color: #fff;
        line-height: 63px;
        text-align: center;
        margin-bottom: 27px;
        // color: ;
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        border: 1px solid #cc1a30;
      }
      .info_block_active {
        background: #cc1a30;
        color: #ffffff;
      }
      //

      .tab-one-box {
        display: inline-block;
        width: 224px;
        height: 63px;
        margin-right: 50px;
        border-top-right-radius: 30px;
        background: #cc1a30;
        line-height: 60px;
        text-align: center;
        margin-bottom: 27px;
        color: #ffffff;
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
      }

      .course_flex_block {
        width: 100%;
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        .course_item {
          width: 286px;
          height: 166px;
          background: #ffffff;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
          box-sizing: border-box;
          padding: 36px 55px 30px;
          margin-right: 69px;
          margin-bottom: 73px;
          position: relative;
          color: #333;
          @include flex(column, space-between, flex-start);

          .bar {
            width: 66px;
            height: 4px;
            background: #333333;
          }

          .title {
            font-size: 24px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
          }

          .desc {
            font-size: 20px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
          }
        }

        .course_item:nth-child(4n) {
          margin-right: 0;
        }
        .video_acitve {
          background: #cc1a30;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
          color: #fff;
          .bar {
            background: #fff !important;
          }
        }
        .pgs_course{
          width: 100%;
          @include flex(row, flex-start, center);
          margin-top:20px;
        }
        .pgs_course_item {
          width: 151px;
          height: 130px;
          background: #ffffff;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
          box-sizing: border-box;
          padding: 18px 20px 15px;
          margin-right: 20px;
          margin-bottom: 27px;
          position: relative;
          color: #333;
          @include flex(column, space-between, flex-start);

          .bar {
            width: 66px;
            height: 4px;
            background: #333333;
          }

          .title {
            font-size: 24px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
          }

          .desc {
            font-size: 20px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
          }
        }

        .pgs_course_item:nth-child(8) {
          margin-right: 0;
        }
        // .video_acitve {
        //   background: #cc1a30;
        //   box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
        //   color: #fff;
        //   .bar {
        //     background: #fff !important;
        //   }
        // }
      }
    }

    .btns {
      @include flex(row, flex-end, center);
      margin-bottom: 85px;

      .btn {
        width: 183px;
        height: 54px;
        background: #3565b5;
        font-size: 25px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        @include flex();
      }
    }

    .active {
      background: #cc1a30 !important;
    }

    .look {
      width: 37px;
      height: 37px;
      background: #cc1a30;
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: 0;
      @include bg("../../assets/img/lesson/look2.png");
    }

    .lock {
      width: 37px;
      height: 37px;
      background: #3565b5;
      position: absolute;
      padding: 4px 7px 7px 6px;
      box-sizing: border-box;
      top: 0;
      right: 0;
      @include bg("../../assets/img/lesson/lock2.png");
    }
  }
  .pgs_cont{
    @include flex(row, center, flex-start);
    height: 60px;
    line-height: 60px;
    font-size:22px;
    color:#FFFFFF;
  }
  .pgs_cont_teacher{
    padding-left: 10px;
    width: 166px;
    background: #cc1a30;
    border-radius: 0 30px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .pgs_cont_dec{
    margin-left:20px;
    padding-left: 50px;
    width: 1104px;
    border-radius: 30px 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background: #cc1a30 url('../../assets/img/course/cricle.png') no-repeat 28px center;
    background-size: 15px 15px;
  }
}
</style>
